import axios from '@/utils/axios';

export function getCountries() {
	return axios.get(`/countries`);
}

export function getCountryById(id) {
	return axios.get(`/countries/${id}`);
}

export function addCountry(data) {
	let form = new FormData();
	if (data.flag) form.append('flag', data.flag);
	form.append('name', data.name);
	form.append('ISO2', data.ISO2 || '');
	form.append('ISO3', data.ISO3 || '');
	form.append('region', data.region || '');
	return axios.post(`/countries`, form);
}

export function updateCountry(id, data) {
	let form = new FormData();
	if (data.flag) form.append('flag', data.flag);
	form.append('name', data.name);
	form.append('ISO2', data.ISO2 || '');
	form.append('ISO3', data.ISO3 || '');
	form.append('region', data.region || '');
	return axios.post(`/countries/${id}/update`, form);
}

export function deleteCountries(data) {
	return axios.post(`/countries/deleteMany`, data);
}
