<template>
	<router-view />
</template>
<script>
	import mixin from '@/mixins/data.js';
	export default {
		name: 'App',
		mixins: [mixin],
		async created() {
			this.$store.commit('setCountryPageSummaries', 'all');
			// this.$store.commit('setMapCountries', {});
			window.addEventListener('resize', this.SET_WIDTH);
			await this.loadData();

			// let _countries = this.$store.getters.getCountries;
			// for (let i = 0; i < _countries.length; i++) {
			// 	setTimeout(() => {
			// 		this.$router.push({
			// 			query: {
			// 				show: false,
			// 			},
			// 		});
			// 		console.log(_countries[i].name);
			// 		this.$store.commit('setActiveCountry', _countries[i]);
            //         this.$nextTick(() => {
            //             this.$router.push({
            //                 query: {
            //                     show: true,
            //                 },
            //             });
            //         });
			// 	}, 500 * (i + 1));
			// }
		},
		methods: {
			SET_WIDTH() {
				this.$store.commit('setWidth', window.innerWidth);
			},
		},
	};
</script>
<style>
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
</style>
