import axios from 'axios';
import VueInstance from '@/main';
// axios.defaults.timeout = 30000;
// MIDDLEWARE
axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status == 401 && VueInstance.$route.name != 'Backoffice-Auth') {
			VueInstance.$store.commit('backoffice/setToken', null);
			VueInstance.$buefy.toast.open({
				message: 'Your session has expired. Please login again.',
				type: 'is-danger',
				duration: 5000,
			});
			return VueInstance.$router.push({ name: 'Backoffice-Auth' });
		}
		return Promise.reject(error);
	}
);

const getHeaders = () => {
	let token = VueInstance.$store.getters['backoffice/getToken'];
	if (token) {
		return {
			Authorization: `Bearer ${token}`,
		};
	}
};

export function post(uri, data) {
	return axios.post(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function put(uri, data) {
	return axios.put(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function remove(uri, data) {
	return axios.delete(endpoint(uri), {
		data,
		headers: getHeaders(),
	});
}

export function get(uri) {
	return axios.get(endpoint(uri), {
		headers: getHeaders(),
	});
}

export function endpoint(uri) {
	return process.env.VUE_APP_API_URL + '/api' + uri;
}

export function formData(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.post(endpoint(uri), data, config);
}

export function formDataPut(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.put(endpoint(uri), data, config);
}

export default {
	get,
	post,
	put,
	remove,
	formData,
	formDataPut,
};
