import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { ToastProgrammatic as Toast } from "buefy";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Map",
    component: () => import(/* webpackChunkName: "map" */ "../views/Map.vue"),
  },
  {
    path: "/country-profile",
    name: "CountryProfile",
    component: () =>
      import(/* webpackChunkName: "map" */ "../views/CountryProfile.vue"),
  },
  {
    path: "/country-profile/preview",
    name: "CountryProfilePreview",
    component: () =>
      import(/* webpackChunkName: "map" */ "../views/CountryProfile.vue"),
  },
  {
    path: "/backoffice",
    name: "Backoffice",
    component: () =>
      import(/* webpackChunkName: "backoffice" */ "../views/Backoffice.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "summary-data",
        name: "Backoffice-SummaryData",
        component: () =>
          import(
            /* webpackChunkName: "backoffice" */ "../views/Backoffice/SummaryData.vue"
          ),
        meta: {
          roles: ["admin", "editor"],
        },
      },
      {
        path: "auth",
        name: "Backoffice-Auth",
        component: () =>
          import(
            /* webpackChunkName: "backoffice" */ "@/views/Backoffice/Auth.vue"
          ),
        meta: {
          hideSidebar: true,
          roles: ["admin", "editor"],
        },
      },
      {
        path: "my-account",
        name: "Backoffice-MyAccount",
        component: () =>
          import(
            /* webpackChunkName: "backoffice" */ "@/views/Backoffice/MyAccount.vue"
          ),
        meta: {
          roles: ["admin", "editor"],
        },
      },
      {
        path: "users",
        name: "Backoffice-Users",
        component: () =>
          import(
            /* webpackChunkName: "backoffice" */ "@/views/Backoffice/Users.vue"
          ),
        meta: {
          roles: ["admin"],
        },
      },
      {
        path: "countries",
        name: "Backoffice-Countries",
        component: () =>
          import(
            /* webpackChunkName: "backoffice" */ "@/views/Backoffice/Countries.vue"
          ),
        meta: {
          roles: ["admin", "editor"],
        },
      },
      {
        path: "countries/:id",
        name: "Backoffice-Country",
        component: () =>
          import(
            /* webpackChunkName: "backoffice" */ "@/views/Backoffice/Country.vue"
          ),
        meta: {
          roles: ["admin", "editor"],
        },
      },
      {
        path: "countries/organization/:id",
        name: "Backoffice-Organization",
        component: () =>
          import(
            /* webpackChunkName: "backoffice" */ "@/views/Backoffice/Organization.vue"
          ),
        meta: {
          roles: ["admin", "editor"],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        // , offset: { x: 0, y: 10 }
      };
    }
  },
});

// verify meta roles
router.beforeEach((to, from, next) => {
  window.parent.postMessage(`route-change-${to.name}`, "*");
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.name != "Backoffice-Auth"
  ) {
    const user = store.getters["backoffice/getUser"];
    if (!user) {
      return next({
        path: "/backoffice/auth",
        query: { redirect: to.fullPath },
      });
    } else {
      if (to.matched.some((record) => record.meta.roles)) {
        const roles = to.meta.roles;
        if (roles.includes(user.role)) {
          return next();
        } else {
          Toast.open({
            message: "You don't have permission to access this page.",
            type: "is-danger",
            position: "is-bottom-right",
          });
          return next({
            path: "/",
          });
        }
      } else {
        return next();
      }
    }
  }

  return next();
});

export default router;
