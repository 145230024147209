export const state = () => ({
	countries: [],
	token: null,
	user: null,
});

export const getters = {
	getToken: (state) => state.token,
	getUser: (state) => state.user,
};

export const mutations = {
	setToken(state, token) {
		state.token = token;
	},
	setUser(state, user) {
		state.user = user;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
