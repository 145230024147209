const currentVersion = localStorage.getItem(`${process.env.VUE_APP_NAME}_VERSION`);
if (currentVersion != process.env.VUE_APP_VERSION) {
	console.log('new version');
	localStorage.removeItem(`${process.env.VUE_APP_NAME}`);
}
localStorage.setItem(`${process.env.VUE_APP_NAME}_VERSION`, process.env.VUE_APP_VERSION);

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import "quill/dist/quill.snow.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
import 'svgmap/dist/svgMap.min.css';
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Buefy, {
	defaultIconComponent: 'font-awesome-icon',
	defaultIconPack: 'fas',
    defaultProgrammaticPromise: true,
});
import VueSmoothScroll from 'vue2-smooth-scroll';
Vue.use(VueSmoothScroll, {
	duration: 1000,
	updateHistory: false,
});
Vue.config.productionTip = false;

import moment from 'moment';
moment.locale(window.navigator.userLanguage || window.navigator.language);
Vue.prototype.$moment = moment;

import utils from './mixins/utils';
Vue.mixin(utils);
const VueInstance = new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');

export default VueInstance;
