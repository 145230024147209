import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	key: process.env.VUE_APP_NAME,
});

import backoffice from '@/store/modules/backoffice';

export default new Vuex.Store({
	state: {
		activeLabel: [0, 0, 0],
		activeCountry: null,
		windowWidth: window.innerWidth,

		// data
		hierarchy: [],
		countryPageSummaries: 'full',
		countries: [],
		countriesByTheme: [],

		selectedTheme: null,
		showThemesDomestic: true,
		showThemesInternational: true,

		viewingCarousel: true,
		allDataLoaded: false,
	},
	getters: {
		getActiveLabel(state) {
			return state.activeLabel;
		},
		getActiveCountry(state) {
			return state.activeCountry;
		},
		getHierarchy(state) {
			return state.hierarchy;
		},
		getCountries(state) {
			return state.countries;
		},
		getViewingCarousel(state) {
			return state.viewingCarousel;
		},
		getAllDataLoaded(state) {
			return state.allDataLoaded;
		},
		getMapCountries(state) {
			return state.mapCountries;
		},
		getWindowWidth(state) {
			return state.windowWidth;
		},
		getCountryPageSummaries(state) {
			return state.countryPageSummaries;
		},
		getCountriesByTheme(state) {
			return state.countriesByTheme;
		},
		getSelectedTheme(state) {
			return state.selectedTheme;
		},
		getShowThemesDomestic(state) {
			return state.showThemesDomestic;
		},
		getShowThemesInternational(state) {
			return state.showThemesInternational;
		},
	},
	mutations: {
		setActiveLabel(state, label) {
			state.activeLabel = label;
		},
		setActiveCountry(state, country) {
			state.activeCountry = country;
		},
		setWidth(state, width) {
			state.windowWidth = width;
		},

		setHierarchy(state, data) {
			state.hierarchy = data;
		},
		setMapCountries(state, data) {
			state.mapCountries = data;
		},
		setCountryPageSummaries(state, data) {
			state.countryPageSummaries = data;
		},
		setViewingCarousel(state, data) {
			state.viewingCarousel = data;
		},
		setAllDataLoaded(state, data) {
			state.allDataLoaded = data;
		},
		setCountries(state, data) {
			state.countries = data;
		},
		setCountriesByTheme(state, data) {
			state.countriesByTheme = data;
		},
		setSelectedTheme(state, data) {
			state.selectedTheme = data;
		},
		setShowThemesDomestic(state, data) {
			state.showThemesDomestic = data;
			if (!data) {
				state.showThemesInternational = true;
			}
		},
		setShowThemesInternational(state, data) {
			state.showThemesInternational = data;
			if (!data) {
				state.showThemesDomestic = true;
			}
		},
	},
	modules: {
		backoffice,
	},
	plugins: [vuexLocal.plugin],
});
